.navbar {
    background-color: #1E1E1E;
    border-bottom: 1px solid #e9ecef;
    padding: 2px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
}

.navbar-logo-container {
    padding: 5px;
    padding-left: 50px;
}

.navbar-logo {
    height: 50px;
}

.navbar-logo:hover {
    cursor: pointer;
}

.user-profile {
    padding-right: 50px;
}

.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #2E2E2E;
    border-radius: 20px;
    padding: 5px 6px;
    transition: background-color 0.3s ease;
}

.user-info:hover {
    background-color: #3E3E3E;
}

.user-avatar-name {
    display: flex;
    align-items: center;
}

.user-avatar {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    margin-right: 10px !important;
    object-fit: cover !important;
}

.user-name {
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
}

.dropdown-arrow {
    margin-right: 3px;
    font-size: 12px;
    transition: transform 0.3s ease;
}

.ant-dropdown-open .dropdown-arrow {
    transform: rotate(180deg);
}

.custom-dropdown-menu {
    background-color: #2E2E2E;
    border: 1px solid #3E3E3E;
    border-radius: 8px;
    padding: 8px 0;
    min-width: 120px;
    max-width: 180px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.custom-dropdown-menu button {
    display: block;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    background: none;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-dropdown-menu button:hover {
    background-color: #3E3E3E;
    color: #1890ff;
}

.custom-dropdown-menu .divider {
    height: 1px;
    background-color: #3E3E3E;
    margin: 4px 0;
}

@media (max-width: 576px) {
    .user-profile {
        padding-right: 25px;
    }

    .user-name {
        font-size: 14px;
    }

    .navbar-logo-container {
        padding: 5px;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .navbar-logo {
        height: 45px;
    }

    .user-info {
        padding: 3px 5px;
    }

    .user-avatar {
        width: 28px !important;
        height: 28px !important;
    }

    .dropdown-arrow {
        font-size: 10px;
    }
}
