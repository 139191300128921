.leftcolumn {
    position: relative;
    text-align: center;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px dashed #000000;
    height: calc(100vh - 45px);
    min-height: 500px;
}

.rightcolumn {
    background-color: #D9D9D9;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightside-container4 {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.analizar-title {
    text-align: left;
    margin-top: -2px;
}

.loginInput2 {
    width: 100%;
    margin-bottom: 15px;
    height: 35px;
}

.analizar-button {
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    background-color: #39A9DF;
    color: #fff;
    position: relative;
    /* Añadido para el posicionamiento del input oculto */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* Asegura que el input no se extienda fuera del botón */
}

.analizar-button:hover {
    border: solid 2px #39A9DF;
    color: #39A9DF;
}

.custom-col-24 {
    width: calc(24 / 24 * 100%);
}

.custom-col-11-6 {
    width: calc(11.6 / 24 * 100%);
}

.custom-col-11-6-edad {
    width: calc(5 / 24 * 100%);
}

.custom-col-11-6-unidad {
    width: calc(5.6 / 24 * 100%);
}

.custom-col-11-6-prefijo {
    width: calc(7 / 24 * 100%);
}

.custom-col-11-6-nombre {
    width: calc(16 / 24 * 100%);
}

.uploaded-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
    max-height: 330px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 100px;
}

.image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;

}

.custom-file-upload {
    display: block;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.custom-file-upload input {
    display: none;
}

.file-list {
    margin-top: 10px;
    list-style: none;
    padding-left: 0;
    font-size: 14px;
}

.file-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.remove-file-button {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 4px;
}


.uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    color: rgb(0, 0, 0);
    border: none;
    padding: 2px 6px;
    cursor: pointer;
    background-color: white;
}

.analizar-button input[type="file"] {
    position: absolute;
    /* Posicionar absolutamente dentro del botón */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* Hacer el input completamente invisible */
    cursor: pointer;
    /* Mostrar cursor de puntero */
}

@media (max-width: 992px) {
    .espaciar {
        margin-bottom: 30px;
    }

    .analizar-title {
        margin-top: 30px;
    }

    .leftcolumn {
        border-right: 0px dashed #000000;
    }
}

.suggestions-list {
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 5px;
}

.suggestions-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.suggestion-item {
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.suggestion-image-container {
    flex-shrink: 0;
    margin-right: 10px;
}

.suggestion-image {
    width: 65px;
    height: 45px;
    object-fit: cover;
    border-radius: 5px;
}

.no-image {
    width: 65px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: #fff;
    border-radius: 10%;
}

.suggestion-name {
    font-size: 12px;
    color: #333;
}

.drag-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.drag-overlay p {
    color: white;
    font-size: 24px;
}

.minimalist-button {
    width: 100%;
    padding: 12px 0px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #ffffff;
    color: #333333;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.minimalist-button:hover {
    background-color: #f5f5f5;
}

.minimalist-button:active {
    background-color: #e8e8e8;
}

.minimalist-button-primary {
    background-color: #e6f7ff;
    border-color: #91d5ff;
    color: #1890ff;
}

.minimalist-button-primary:hover {
    background-color: #bae7ff;
}

.minimalist-button-danger {
    background-color: #fff1f0;
    border-color: #ffa39e;
    color: #ff4d4f;
}

.minimalist-button-danger:hover {
    background-color: #ffccc7;
}

.minimalist-button-secondary {
    background-color: #f0f5ff;
    border-color: #adc6ff;
    color: #2f54eb;
}

.minimalist-button-secondary:hover {
    background-color: #d6e4ff;
}

.button-container3 {
    margin-top: 20px;
}

.upload-button-wrapper {
    width: 100%;
    margin-bottom: 10px;
}

.upload-button-wrapper input[type=file] {
    display: none;
}

.minimalist-button2 {
    width: 40%;
    padding: 8px 0;
    /* Menos padding para reducir altura */
    font-size: 14px;
    /* Texto más pequeño */
    font-weight: 500;
    border-radius: 6px;
    /* Un radio de esquina más pequeño */
    background-color: #ffffff;
    color: #333333;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    /* Espaciado menor entre ícono y texto */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Sombra más sutil */
}

.minimalist-button2:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    /* Sombra más ligera en hover */
}

.minimalist-button2:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Botón 'Limpiar' (peligroso) */
.redesigned-danger-button {
    background-color: #ffebeb;
    border: 1px solid #ff7875;
    color: #ff4d4f;
}

.redesigned-danger-button:hover {
    background-color: #ff4d4f !important;
    color: white !important;
    border-color: #ff4d4f !important;
}

/* Botón 'Continuar' (secundario) */
.redesigned-secondary-button {
    background-color: #e6f3ff;
    border: 1px solid #69c0ff;
    color: #39A9DF;
}

.redesigned-secondary-button:hover {
    background-color: #39A9DF !important;
    color: white !important;
    border-color: #39A9DF !important;
}

/* Botón 'Continuar' (secundario) */
.vistaprevia-button {
    background-color: #e7ffe6 !important;
    border: 1px solid #2ab33c !important;
    color: #2ab33c !important;
}

.vistaprevia-button:hover {
    background-color: #2ab33c !important;
    color: white !important;
    border-color: #2ab33c !important;
}

.vistaprevia-button:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
  
  .vistaprevia-button:disabled:hover {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

.text-rightt {
    text-align: right;
    justify-content: right;
    display: flex;
}