.leftside {
    position: relative;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px dashed #000000;
}

.rightside {
    background-color: #D9D9D9;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.divisor-line {
    position: absolute;
    right: 0;
    height: 40%;
    border-right: 6px solid #39A9DF;
    border-radius: 20px;
}

.logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.logo {
    height: 100px;
    margin-right: 10px;
}

.subtitle {
    font-size: 23px;
    font-weight: 500;
    color: #333;
}

.subtitle2 {
    font-size: 23px;
    font-weight: 500;
    color: #333;
    margin: 0;

}

.separation {
    margin-top: 40px;
    align-items: center;

}

.button-container {
    display: flex;
    align-items: center;
}

.button {
    background-color: #39A9DF;
    color: #fff;
    padding: 10px 20px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 20px;
    animation: pulseColor 1.5s ease-in-out infinite;
    transform-origin: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

@keyframes pulseColor {
    0% {
        transform: scale(1);
        background-color: #39A9DF;
    }

    50% {
        transform: scale(1.1);
        background-color: #2f87b5;
    }

    100% {
        transform: scale(1);
        background-color: #39A9DF;
    }
}

.dogimage {
    width: 430px;
    margin-bottom: -6px;
}

.rightside-container {
    margin-left: 100px;
    margin-top: 80px;
}

.one-secondRow {
    background: #0e1013;
    height: 100%;
    text-align: center;
    position: relative;
    padding: 20px;
}

.image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 10px;
    flex-wrap: wrap;
    /* Esto permite que los elementos dentro de .image-wrapper se ajusten en pantallas más pequeñas */
}

.imgseminario {
    width: 80%;
    /* Esto hace que la imagen ocupe el 80% del contenedor, ajustándose de manera flexible */
    max-width: 800px;
    /* Esto asegura que la imagen no se agrande más de 600px */
    height: auto;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.imgseminario:hover {
    transform: scale(1.05);
}

.left-arrow {
    rotate: -90deg;
    margin-right: 0px;
}

.right-arrow {
    rotate: 90deg;
    margin-left: 0px;
}

.arrow-gif {
    width: 100px;
    height: auto;
    animation: bounce 1s infinite;
}



@keyframes bounce {

    0%,
    100% {
        transform: translateY(-50%);
    }

    50% {
        transform: translateY(-60%);
    }
}

.secondRow {
    background-color: #3A3A3A;
    /* height: 580px; */
    height: 500px;
    text-align: center;
}

.spacing {
    margin-top: 20px;
    justify-content: center;
    text-align: center;
}

.spacing2 {
    margin-top: 20px;
    text-align: center;
}

.benefits-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-left: 0px;
}

.benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.benefits-list li {
    font-size: 1.4rem;
    color: #ffffff;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    line-height: 2;
}

.checkmark {
    color: #39A9DF;
    font-weight: bold;
    margin-right: 12px;
    margin-top: -7px;
    font-size: 2rem;
    line-height: 1;
    vertical-align: middle;
}

.content {
    margin-top: 68px;
}

.bluetitle {
    font-family: poppins;
    text-align: center;
    color: #39A9DF;
    font-weight: 700;
    font-size: 30px;
}

.whitesubtitle {
    margin-top: -20px;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
}

.video-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.video {
    max-width: 600px;
    height: auto;
    border-radius: 8px;
}

.thirdRow {
    background-color: #D9D9D9;
    height: 300px;
    text-align: center;
}

.item {
    font-size: 22px;
}

.item2 {
    margin-top: -30px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
}

.subitem {
    font-size: 12px;
    margin-top: -18px;
}

.itemsRow {
    margin-top: 50px;
}

.itemsRow2 {
    margin-top: 50px;
}

.itemsRow3 {
    margin-top: 50px;
}

.forthRow {
    background-color: #F3F3F3;
    height: 300px;
    text-align: center;
    border-top: 2px dashed #000000;
    border-bottom: 2px dashed #000000;
}

.circle {
    width: 80px;
    height: 80px;
    background-color: #39A9DF;
    border-radius: 50%;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}

.item3 {
    font-size: 22px;
    margin-top: 10px;
}

.subitem2 {
    font-size: 13px;
    margin-top: -20px;
    font-weight: 400;
}

.subitem3 {
    font-size: 13px;
    margin-top: -15px;
    font-weight: 400;
}

.uppercase {
    font-weight: 600;
}

.fifthRow {
    background-color: #D9D9D9;
    height: 250px;
    text-align: center;
    padding-bottom: 100px;
}

.counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #39A9DF;
    border-radius: 12px;
    padding: 6px 1px;
    max-width: 100px;
    margin: auto;
    margin-top: -25px;
    margin-bottom: -15px;
}

.counter {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    margin: 0;
}

.align {
    text-align: center;
    justify-content: center;
}

@media (max-width: 1500px) {
    .separation {
        margin-top: 10px;
    }

    .subtitle2 {
        font-size: 20px;
    }
}



@media (max-width: 1200px) {
    .dogimage {
        width: 350px;
    }

    .rightside-container {
        margin-left: 40px;
        margin-top: 30px;
    }

    .circle {
        width: 70px;
        height: 70px;
    }

    .left-arrow {
        rotate: -90deg;
        margin-right: -100px;
    }
    
    .right-arrow {
        rotate: 90deg;
        margin-left: -100px;
    }

    .arrow-gif {
        width: 80px;
    }
}

@media (max-width: 1140px) {
    .button-container {
        margin-top: 20px;
    }
}

@media (max-width: 992px) {
    .imgseminario {
        max-width: 600px;
    }

    .left-arrow {
        rotate: -90deg;
        margin-right: -70px;
    }
    
    .right-arrow {
        rotate: 90deg;
        margin-left: -70px;
    }

    .subtitle2 {
        font-size: 1rem;
    }

    .separation {
        width: 350px;
    }

    .rightside-container {
        position: absolute;
        margin-top: -300px;
        z-index: 2;
    }

    .leftside {
        text-align: end;
        justify-content: end;
        border-right: 0px dashed #000000;
    }

    .rightside {
        padding: 0px;
    }

    .logo {
        height: 75px;
        margin-right: 0px;
    }

    .subtitle {
        font-size: 1rem;
        width: 400px;
    }

    .whitesubtitle {
        font-size: 16px;
    }

    .video {
        max-width: 500px;
    }

    .secondRow {
        background-color: #3A3A3A;
        /* height: 450px; */
        text-align: center;
    }

    .benefits-list li {
        font-size: 1rem;
    }

    .checkmark {
        margin-top: -7px;
        font-size: 1.6rem;
    }

    .itemsRow {
        margin-top: 0px;
    }

    .item {
        font-size: 18px;
    }

    .item3 {
        font-size: 18px;
    }

    .thirdRow {
        height: 250px;
    }

    .subitem3 {
        font-size: 12px;
        margin-top: -10px;
    }

    .subitem2 {
        margin-top: -10px;
    }

    .forthRow {
        height: 320px;
    }

    .counter-container {
        margin-bottom: -5px;
    }

}

@media (max-width: 768px) {
    .imgseminario {
        max-width: 500px;
    }
    
    .arrow-gif {
        width: 70px;
    }

    .logo {
        height: 60px;
    }

    .subtitle {
        font-size: 0.8rem;
        width: 300px;
    }

    .subtitle2 {
        font-size: 0.8rem;
    }

    .rightside-container {
        position: absolute;
        margin-top: -280px;
        z-index: 2;
    }

    .benefits-list li {
        font-size: 0.9rem;
    }

    .checkmark {
        margin-top: -7px;
        font-size: 1.5rem;
    }

    .whitesubtitle {
        font-size: 15px;
    }

    .video {
        max-width: 400px;
    }

    .secondRow {
        height: 430px;
    }

    .bluetitle {
        font-size: 25px;
    }

    .item3 {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .imgseminario {
        max-width: 450px;
        width: 100%;
    }
    .one-secondRow {
        padding: 0px;
    }
    
    .arrow-gif {
        width: 0px;
    }

    .rightside-container {
        position: absolute;
        margin-top: -450px;
        z-index: 2;
    }

    .dogimage {
        width: 300px;
        margin-bottom: -200px
    }

    .leftside {
        height: 500px;
    }

    .whitesubtitle {
        font-size: 12px;
    }

    .video {
        max-width: 350px;
    }

    .secondRow {
        height: 370px;
    }

    .bluetitle {
        font-size: 18px;
        margin-bottom: 25px;
    }

    .benefits-list li {
        font-size: 0.8rem;
    }

    .checkmark {
        margin-top: -7px;
        font-size: 1.4rem;
    }

    .item {
        font-size: 13px;
    }

    .item2 {
        font-size: 16px;
    }

    .subitem {
        font-size: 10px;
        margin-top: -5px;
    }

    .thirdRow {
        height: 220px;
    }

    .itemsRow {
        margin-top: -20px;
    }

    .item3 {
        font-size: 14px;
    }

    .fifthRow {
        height: 220px;
    }
}