.bodyy {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  padding-top: 68px;
}

.ant-table-wrapper {
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.ant-table {
  border-radius: 8px;
}

.ant-table-cell {
  font-size: 14px !important;
  padding: 8px !important;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 32px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.action-button-placeholder {
  width: 32px; /* Same width as the action buttons */
  height: 34px; /* Same height as the action buttons */
  display: inline-block;
}


.separar {
  margin-right: 4px;
}

.view-button {
  background-color: #1890ff;
  color: white;
}

.delete-button {
  background-color: #ff4d4f;
  color: white;
}

.copy-button {
  background-color: rgb(211, 243, 233) !important;
  color: #20bb9c !important;
}

.copy-button:hover {
  background-color: #20bb9c !important;
  color: #ffffff !important;
}

.download-button {
  /* background-color: #52c41a;
  color: white; */
  min-width: 100px;
}

.continue-button {
  min-width: 100px;
  background-color: #fff3db !important;
  border: 1px solid #faad14 !important;
  color: #faad14 !important;
}

.continue-button:hover {
  min-width: 100px;
  background-color: #faad14 !important;
  border: 1px solid #faad14 !important;
  color: #ffffff !important;
}

.action-button:hover {
  opacity: 0.8;
}

.ant-table-thead .ant-table-cell {
  font-size: 16px !important;
}

.table-container {
  /* max-height: 400px;
  overflow-y: auto; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard-table {
  min-height: 400px;
}

@media (max-width: 1200px) {
  .ant-table-thead .ant-table-cell {
    font-size: 14px !important;
  }

  .ant-table-cell {
    font-size: 12px !important;
    padding: 6px !important;
  }
}
@media (max-width: 1200px) {
  .action-buttons-wrapper {
    display: none;
  }

  .action-dropdown {
    display: inline-block;
  }
}

@media (min-width: 1201px) {
  .action-buttons-wrapper {
    display: flex;
    gap: 5px;
  }

  .action-dropdown {
    display: none;
  }
}

.action-dropdown .ant-dropdown-trigger {
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
}

.action-dropdown .ant-dropdown-trigger:hover {
  color: #1890ff;
  border-color: #1890ff;
}