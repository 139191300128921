.newleftside {
    position: relative;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: end;
    justify-content: center;
    border-right: 2px dashed #000000;
    height: calc(100vh - 192px);
}

.loginInput {
    max-width: 350px;
    margin-bottom: 15px;
    height: 38px;
}

.loginInput3 {
    max-width: 400px;
    margin-bottom: 15px;
    padding: 10px 11px;
}

.SelectInput3 {
    width: 100%;
    margin-bottom: 15px;
    height: 43.6px;
}

.subtitle3 {
    font-family: poppins;
    max-width: 350px;
}

.subtitle4 {
    font-family: poppins;
    max-width: 500px;
    font-size: 16px;
    font-weight: bold;
}
.awaitlogo{
    height: 100px;
}
.decrease {
    margin-bottom: -10px;
}



.button2 {
    background-color: #39A9DF;
    color: #fff;
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
}

.button6 {
    background-color: #39A9DF;
    color: #fff;
    padding: 10px 60px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 1.1rem;
}
.button7 {
    background-color: #39A9DF;
    color: #fff;
    padding: 10px 60px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 1.1rem;
}

.button3 {
    background-color: #df5a39;
    color: #fff;
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 50px;
}

.button4 {
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 40px;
}
.button8 {
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
}

.button-container {
    text-align: center;
    max-width: 350px;
}

.button-container2 {
    justify-content: center;
    text-align: center;
    max-width: 350px;
    margin-bottom: 15px;
}

.login-redirect {
    font-size: 13px;
    margin-right: 24px;
}

.login-redirect:last-child {
    margin-right: 0;
}

.login-redirect:hover {
    color: #39A9DF;
    cursor: pointer;
}

.rightside-container2 {
    margin-left: 100px;
    margin-top: 60px;
}

.rightside-container6 {
    margin-left: 100px;
    margin-top: 60px;
}
.rightside-container3 {
    margin-left: 100px;
}

.dogimage2 {
    width: 500px;
    margin-bottom: -6px;
}

.leftside2 {
    position: relative;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px dashed #000000;
}

.dogimage3 {
    width: 430px;
    margin-bottom: -6px;
}

.container {
    max-width: 500px;
}

.container2 {
    max-width: 400px;
}

.left {
    text-align: left !important;
}

.google-login-button {
    background-color: #4285F4;
    /* Color azul Google */
    color: white;
    border: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.google-login-button:hover {
    background-color: #357AE8;
    /* Azul más oscuro al hacer hover */
}

.google-icon {
    font-size: 20px;
    margin-right: 8px;
    /* Espaciado entre el ícono y el texto */
}

/* Opcional: estilo para estado de clic */
.google-login-button:active {
    background-color: #2A65C7;
    box-shadow: none;
}

.google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    /* Blanco */
    color: #757575;
    /* Gris oscuro */
    border: 1px solid #d9d9d9;
    /* Borde gris claro */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 20px 26px;
    font-weight: 500;
    width: 100%;
    max-width: 400px;
    /* Ancho máximo del botón */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.google-login-button:hover {
    background-color: #f7f7f7;
    /* Un gris más claro en hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    /* Sombra más fuerte en hover */
}

.google-login-button:active {
    background-color: #eeeeee;
    /* Gris más oscuro al hacer clic */
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    /* Reducir la sombra */
}

.google-logo {
    width: 25px;
    /* Tamaño del logo */
    height: 25px;
    margin-right: 8px;
    /* Espaciado entre el logo y el texto */
}

.google-login-text {
    color: #757575;
    /* Gris oscuro */
    font-weight: 500;
    font-size: 20px;
}

@media (max-width: 1700px) {
    .dogimage2 {
        width: 450px;
    }
}

@media (max-width: 1200px) {
    .rightside-container2 {
        margin-left: 40px;
        margin-top: 40px;
    }
    .rightside-container6 {
        margin-left: 40px;
        margin-top: 40px;
    }

    .rightside-container3 {
        margin-left: 40px;
        margin-top: -20px;
    }

    .dogimage2 {
        width: 350px;
    }

    .dogimage3 {
        width: 400px;
    }
}

@media (max-width: 992px) {

    .newleftside {
        text-align: end;
        justify-content: end;
        border-right: 0px dashed #000000;
    }

    .rightside-container2 {
        position: absolute;
        margin-top: -400px;
        z-index: 2;
    }
    .rightside-container6 {
        position: absolute;
        margin-top: -450px;
        z-index: 2;
    }

    .rightside-container3 {
        position: absolute;
        margin-top: -400px;
        z-index: 2;
    }

    .leftside2 {
        text-align: end;
        justify-content: end;
        border-right: 0px dashed #000000;
    }
}

@media (max-width: 768px) {
    .rightside-container2 {
        position: absolute;
        margin-top: -340px;
        z-index: 2;
    }
    .rightside-container6 {
        position: absolute;
        margin-top: -430px;
        z-index: 2;
    }

    .rightside-container3 {
        position: absolute;
        margin-top: -390px;
        z-index: 2;
    }
}

@media (max-width: 576px) {
    .container2 {
        max-width: 300px;
    }
    .button7 {
        background-color: #39A9DF;
        color: #fff;
        padding: 10px 40px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        font-size: 1rem;
    }
    .subtitle4 {
        font-family: poppins;
        max-width: 500px;
        font-size: 13px;
        font-weight: bold;
    }

    .awaitlogo{
        height: 60px;
    }

    .newleftside {
        height: 500px;
    }

    .rightside-container2 {
        position: absolute;
        margin-top: -450px;
        z-index: 2;
    }

    .rightside-container6 {
        position: absolute;
        margin-top: -490px;
        z-index: 2;
    }

    .loginInput3 {
        max-width: 300px;
        margin-bottom: 12px;
        padding: 10px 11px;
    }
    
    .subtitle3 {
        font-family: poppins;
        max-width: 350px;
        font-size: 12px;
    }

    .rightside-container3 {
        position: absolute;
        margin-top: -570px;
        z-index: 2;
    }

    .loginInput {
        max-width: 300px;
        margin-bottom: 15px;
        height: 38px;
    }

    .login-redirect {
        font-size: 11px;
    }

    .dogimage2 {
        width: 290px;
    }

    .dogimage3 {
        width: 290px;
        margin-bottom: -290px
    }

    .leftside2 {
        height: 570px;
    }
}