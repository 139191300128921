.analysis-container {
    height: 100vh;
    display: flex;
    overflow: hidden;
  }
  
  .content2 {
    display: flex;
    width: 100%;
  }
  
  .columna-izq {
    background-color: #232323;
    padding: 10px;
    overflow-y: auto;
    max-height: 100%;
    flex: 0 0 16.66%;
    text-align: center;
  }
  
  .columna-cen {
    background-color: #292929;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: width 0.3s;
    flex-grow: 1;
  }
  
  .columna-der-resizable {
    height: 100%;
    position: relative;
    transition: width 0.1s ease;
  }
  
  .resize-handle {
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    width: 20px;
    cursor: col-resize;
    z-index: 10;
  }
  
  .resize-handle::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .resize-handle:hover::before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .columna-der {
    background-color: #ffffff;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  
  .image-thumbnail {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .thumbnail-image {
    width: 100%;
    height: auto;
  }
  
  .full-image {
    max-width: 800px;
    max-height: 555px;
    width: auto;
    height: auto;
    object-fit: contain;
    pointer-events: none;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  
  .image-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    height: calc(100% - 74px);
    align-items: center;
  }
  
  .image-wrapper canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .default-text {
    color: #ffffff;
    text-align: center;
    font-size: 18px;
  }
  
  .superior-col {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    background-color: #444;
    padding: 5px;
    border-radius: 5px;
  }
  
  .invert-slider,
  .contrast-slider {
    width: 150px;
  }
  
  .diagnostico {
    margin: 15px;
  }
  
  .zoom {
    background-color: inherit;
    border: 0px;
  }
  
  .zoom.anticon svg {
    font-size: 30px;
  }
  
  .zoom:hover {
    background-color: inherit !important;
    border: 0px;
    cursor: zoom-in;
  }
  
  .no-select {
    user-select: none;
    -webkit-user-drag: none;
  }
  
  .right-col-title {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .generate-button {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 0px;
  }
  
  .generate-button2 {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .checkboxx {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .vistaprevia-modal {
    margin-top: -20px;
  }
  
  .preview-title {
    margin-block-start: 0.6em !important;
    margin-block-end: 0.6em !important;
  }
  
  .separate {
    margin-bottom: 20px;
  }
  
  .input-row .ant-collapse {
    background-color: transparent;
    border: none;
    width: 100%;
  }
  
  .ant-select,
  .ant-input {
    width: 100%;
  }
  
  .ant-collapse-item .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .regenerate-button {
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .margtop {
    margin-top: 10px;
  }
  
  .custom-input {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 40px;
  }
  
  .left-part1 {
    width: 80%;
    background-color: #6ac16a;
  }
  
  .left-part2 {
    width: 80%;
    background-color: #39a9df;
  }
  
  .left-part3 {
    width: 80%;
    background-color: #e43c39;
  }
  
  .right-split1 {
    width: 20%;
    display: flex;
    flex-direction: column;
  }
  
  .right-split2 {
    width: 20%;
    display: flex;
    flex-direction: column;
  }
  
  .right-split3 {
    width: 20%;
    display: flex;
    flex-direction: column;
  }
  
  .top-right1 {
    height: 50%;
    background-color: #608a52;
    border: solid 1px #78bc61;
  }
  
  .top-right2 {
    height: 50%;
    background-color: #0079b3;
    border: solid 1px #39a9df;
  }
  
  .top-right3 {
    height: 50%;
    background-color: #b80c09;
    border: solid 1px #e43c39;
  }
  
  .bottom-right1 {
    height: 50%;
    background-color: #608a52;
    border: solid 1px #78bc61;
  }
  
  .bottom-right2 {
    height: 50%;
    background-color: #0079b3;
    border: solid 1px #39a9df;
  }
  
  .bottom-right3 {
    height: 50%;
    background-color: #b80c09;
    border: solid 1px #e43c39;
  }
  
  @media (max-width: 768px) {
    .thumbnail-image {
      width: 70%;
      height: auto;
    }
  
    .image-thumbnail {
      justify-content: center;
      text-align: center;
    }
  
    .columna-izq {
      grid-template-columns: repeat(3, 1fr);
      display: grid;
      justify-content: center;
      max-height: 400px;
      overflow-y: auto;
    }
  
    .default-text {
      margin-bottom: 50px;
    }
  
    .content2 {
      height: 100%;
      flex-direction: column;
    }
    .analysis-container {
      flex-direction: column;
      height: auto;
    }
  
    .columna-izq,
    .columna-cen,
    .columna-der-resizable {
      width: 100% !important;
    }
  
    .columna-izq {
      max-height: 200px;
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
    }
  
    .image-thumbnail {
      flex: 0 0 auto;
      width: 100px;
      margin-right: 10px;
    }
  
    .resize-handle {
      display: none;
    }
  }
  
  .skeleton-loader {
    width: 100%;
    height: 120px;
    background-color: #e7e7e7;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-loader::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%);
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  
  .dots span {
    animation: dots 1.5s infinite;
    animation-delay: calc(var(--i) * 0.3s);
    display: inline-block;
    margin-left: 3px;
    font-size: 25px;
  }
  
  .dots {
    margin-left: 3px;
  }
  
  @keyframes dots {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .generating {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px;
  }
  
  .collapse {
    margin-top: 20px;
  }
  
  .custom-textarea {
    max-height: 200px;
  }
  .selectfield {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
  
  .ant-select .ant-select-selector {
    text-align: left;
  }
  
  .spaces {
    margin: 10px;
  }
  
  .button5 {
    padding: 5px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 40px;
    margin-left: 10px;
  }
  
  .zoom2.active {
    background-color: #4b96d6;
    color: white;
  }
  
  .image-wrapper {
    position: relative;
    overflow: hidden;
  }
  
  .arrows {
    margin-left: 60px;
  }
  
  .zoom2 {
    background-color: inherit;
    border: 0px;
  }
  
  .zoom2.anticon svg {
    font-size: 30px;
  }
  
  .zoom2:hover {
    background-color: inherit !important;
    border: 0px;
    cursor: pointer;
  }
  
  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    left: -5px;
    cursor: col-resize;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .columna-der {
    transition: width 0.3s;
    height: 100%;
    overflow-y: auto;
  }
  
  .image-thumbnail {
    position: relative;
  }
  
  .delete-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    padding: 2px;
    cursor: pointer;
  }
  
  .add-image-button {
    margin-top: 10px;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  