.footer {
    width: 100%;
    background-color: #3E3E3E;
    color: aliceblue;
    justify-content: center;
    height: 80px;
}

.center {
    justify-content: center;
    text-align: center;
}

.iconsize {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.iconsize:hover {
    cursor: pointer;
}

.iconsize.anticon svg {
    font-size: 35px;
}

.circle2 {
    border-width: 2px;
    border-radius: 50%;
    margin: 0 10px;
    display: inline-block;
    margin-top: 12.5px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.circle2:hover {
    background-color: white;
}

.circle2:hover .iconsize {
    color: #3E3E3E;
}

.footer2 {
    width: 100%;
    background-color: #1E1E1E;
    color: aliceblue;
    justify-content: center;
    height: auto;
    padding: 20px;
}

.titlee {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.subitemm {
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 3px;
    
}

.subitemm2 {
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 3px;
}

.email-link {
    color: #39A9DF; /* Color del enlace */
    text-decoration: none; /* Quita el subrayado */
}

.email-link:hover {
    text-decoration: underline; /* Agrega subrayado al pasar el mouse */
}

.leftcol {
    padding-left: 100px;
}

.rightcol {
    text-align: right;
    padding-right: 100px;
}

.alinear {
    text-align: center;
    font-style: italic;
}

@media (max-width: 1200px) {
    .alinear {
        margin-top: 20px;
    }
}

@media (max-width: 1200px) {
    .leftcol {
        padding-left: 0px;
    }

    .rightcol {
        padding-right: 0px;
    }
}

@media (max-width: 571px) {
    .leftcol {
        padding-left: 0px;
        text-align: center;
    }

    .rightcol {
        padding-right: 0px;
        text-align: center;
        padding-top: 20px;
    }

    .lastRow {
        justify-content: center;
    }

    .subitemm2 {
        font-size: 11px;
    }
}

.link{
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
}