/* Contenedor principal */
.date-picker-wrapper.floating-input {
    position: relative;
    margin-bottom: 12px;
    width: 100%;
  }
  
  /* Contenedor del datepicker */
  .date-picker-wrapper .datepicker-container {
    width: 100%;
  }
  
  /* Reset de estilos del wrapper de react-datepicker */
  .date-picker-wrapper .react-datepicker-wrapper {
    width: 100%;
    display: block;
  }
  
  /* Reset del contenedor de input de react-datepicker */
  .date-picker-wrapper .react-datepicker__input-container {
    width: 100%;
    display: block;
  }
  
  /* Estilos del input */
  .date-picker-wrapper .custom-datepicker.ant-input {
    width: 100%;
    padding: 7px 11px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;  /* Añadido border-radius */
    transition: all 0.3s;
    height: 40px;
    background-color: white;
    box-sizing: border-box;
    display: block;
  }
  
  /* Label flotante */
  .date-picker-wrapper label {
    position: absolute;
    left: 11px;
    top: 45%;
    transform: translateY(-50%);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
    transition: all 0.3s;
    background: transparent;
    padding: 0 2px;
    z-index: 1;
  }
  
  /* Label cuando está enfocado o tiene valor */
  .date-picker-wrapper.focused label,
  .date-picker-wrapper .ant-input:not(:placeholder-shown) + label {
    top: 0;
    left: 8px;
    font-size: 12px;
    color: #aeaeae;
    background-color: white;
    transform: translateY(-50%);
    padding: 0 2px;
  }
  
  /* Estilos de error */
  .date-picker-wrapper.error .custom-datepicker {
    border-color: #ff4d4f;
  }
  
  .date-picker-wrapper.error label {
    color: #ff4d4f;
  }
  
  /* Ocultar ícono de calendario por defecto */
  .react-datepicker__calendar-icon {
    display: none;
  }
  
  /* Estilos para el calendario emergente */
  .react-datepicker {
    font-family: inherit;
    border-radius: 6px;
  }
  
  .react-datepicker__header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #d9d9d9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
  .react-datepicker__current-month {
    text-transform: capitalize;
  }
  
  .react-datepicker__day-name {
    text-transform: capitalize;
  }
  
  /* Asegurar que el popup del calendario aparezca por encima */
  .react-datepicker-popper {
    z-index: 1000;
  }
  
  /* Remover cualquier padding o margin adicional que pueda afectar las dimensiones */
  .date-picker-wrapper .react-datepicker__input-container input {
    margin: 0;
    padding: 7px 11px;
  }
  
  