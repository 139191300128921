.floating-select {
  position: relative;
  margin-bottom: 20px;
}

.floating-select .ant-select {
  width: 100%;
}

.floating-select .ant-select-selector {
  padding: 0 11px !important;
  height: 40px !important;
  border: 1px solid #d9d9d9 !important;
  transition: all 0.3s !important;
  background-color: white !important;
  display: flex !important;
  align-items: center !important;
}

.floating-select .ant-select-selection-search {
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
}

.floating-select .ant-select-selection-search-input {
  height: 100% !important;
}

.floating-select .ant-select-selection-placeholder,
.floating-select .ant-select-selection-item {
  line-height: 38px !important;
  transition: all 0.3s !important;
}

.floating-select .ant-select-arrow {
  top: 50% !important;
  margin-top: -6px !important;
  height: 20px !important;
}

.floating-select label {
  position: absolute;
  left: 11px;
  top: 60%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
  transition: all 0.3s;
  background: transparent;
  padding: 0 2px;
}

.floating-select.focused label,
.floating-select .ant-select-selection-item + label,
.floating-select.multiple.focused label,
.floating-select.multiple .ant-select-selection-item ~ label {
  top: 0px;
  left: 8px;
  font-size: 12px;
  color: #aeaeae;
  background-color: white;
  transform: translateY(-50%);
  padding: 0 2px;
}

.floating-select.error .ant-select-selector {
  border-color: #ff4d4f !important;
}

.floating-select.error.focused .ant-select-selector {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}

.floating-select.error label {
  color: #ff4d4f;
}

.floating-select.full-width {
  width: 100%;
}

.floating-select .ant-select-clear {
  right: 35px !important;
  top: 18px;
}

.floating-select .ant-select-arrow {
  right: 11px !important;
}

.floating-select .ant-select-selection-search {
  left: 11px !important;
  right: 36px !important;
}

/* Estilos para el select deshabilitado */
.floating-select .ant-select-disabled .ant-select-selector {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
}

.floating-select .ant-select-disabled .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25) !important;
}

.floating-select .ant-select-disabled .ant-select-arrow {
  color: rgba(0, 0, 0, 0.25) !important;
}

.floating-select .ant-select-disabled + label {
  color: rgba(0, 0, 0, 0.25) !important;
}

.floating-select .ant-select-disabled:hover .ant-select-selector {
  border-color: #d9d9d9 !important;
}

/* Estilos para selects de multiopción */
.floating-select.multiple .ant-select-selector {
  padding: 0px 11px !important;
  min-height: 40px !important;
  height: auto !important;
  align-items: flex-start !important;
  flex-wrap: wrap !important;
}

.floating-select.multiple .ant-select-selection-search {
  height: 24px !important;
  margin: 3px 0 !important;
}

.floating-select.multiple .ant-select-selection-search-input {
  height: 24px !important;
}

.floating-select.multiple .ant-select-selection-item {
  height: 24px !important;
  line-height: 22px !important;
  margin-top: 7px !important;
  margin-bottom: 3px !important;
}

.floating-select.multiple .ant-select-selection-placeholder {
  line-height: 30px !important;
}

.floating-select.multiple label {
  top: 50%;
}

.floating-select.multiple .ant-select-arrow {
  top: 40% !important;
  margin-top: -6px !important;
  height: 20px !important;
}

/* Hide delete icon in selected items */
.floating-select .ant-select-selection-item .anticon-close-circle {
  display: none !important;
}

/* Show delete icon only in dropdown options */
.ant-select-dropdown .ant-select-item-option-content .anticon-close-circle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: inline-block !important;
  background-color: white;
}

