.floating-input {
  position: relative;
  margin-bottom: 12px;
}

.floating-input .ant-input {
  width: 100%;
  padding: 7px 11px;  /* Aumentado el padding vertical */
  font-size: 14px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  height: 40px;  /* Altura fija para el input */
  background-color: white; /* Asegura que el fondo del input sea blanco */
}

.floating-input label {
  position: absolute;
  left: 11px;
  top: 45%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
  transition: all 0.3s;
  background: transparent;
  padding: 0 2px;
}

.floating-input.focused label,
.floating-input .ant-input:not(:placeholder-shown) + label {
  top: 0;  /* Ajustado para que quede en el borde superior */
  left: 8px;
  font-size: 12px;
  color: #aeaeae;
  background-color: white; /* Cambiado de #d9d9d9 a white */
  transform: translateY(-50%);  /* Ajustado para centrar el label en el borde */
  padding: 0 2px; /* Añadido para dar un poco de espacio alrededor del texto */
}

.floating-input.error .ant-input {
  border-color: #ff4d4f;
}

.floating-input.error label {
  color: #ff4d4f;
}


/* Estilos para el input deshabilitado */
.floating-input.disabled input {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: #bfbfbf;
  cursor: not-allowed;
}

.floating-input.disabled label {
  color: #bfbfbf;
}

/* Asegurarse de que el label permanezca arriba cuando está deshabilitado y tiene valor */
.floating-input.disabled.focused label,
.floating-input.disabled input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  background-color: #f5f5f5;
  padding: 0 5px;
}
