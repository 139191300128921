.image-grid2 {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 20px;
    width: 100%;
    align-items: center;
    margin-top: 70px;
    overflow-y: auto;
    max-height: 460px;
}

.rightside-container5 {
    height: 600px;
}

.separacion {
    margin-bottom: 30px;
}

.separacion2 {
    margin-bottom: 10px;
}

.hover-region {
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.analizar {
    margin-top: -45px;
}

.interactive-area {
    border: 2px solid red;
    background-color: rgba(255, 0, 0, 0.2);
    cursor: pointer;
}

.image-preview {
    position: relative;
}

.anticon svg {
    font-size: 16px;
}

.celda-dictado {
    font-family: 'Poppins', sans-serif;
}

.button2.disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
    border: 1px solid #ccc;
}

.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-viewer-container {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.image-viewer-controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.tooltip {
    visibility: hidden;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    transform: translate(-50%, -100%);
    top: -10px;
}

.hover-region:hover .tooltip {
    visibility: visible;
}

.label-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.label-point {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.label-text {
    position: absolute;
    left: 15px;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
}

.nota-item {
    position: relative; /* Necesario para posicionar la "x" en la esquina */
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #d9d9d9;

}

.nota-item p {
    margin: 5px 0;
    color: #333;
    font-size: 14px;
}

/* Botón de eliminación en la esquina */
.nota-item .delete-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    color: #ff4d4f; /* Color de alerta */
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s;
}

.nota-item .delete-button:hover {
    color: #ff7875; /* Color de hover */
}

.custom-textarea {
    scrollbar-width: thin; /* Scroll delgado para navegadores compatibles (Firefox) */
    scrollbar-color: #39A9DF #f3f3f3; /* Scroll azul y fondo gris claro (Firefox) */
    max-height: 200px; /* Altura máxima para activar el scroll */
    overflow-y: auto; /* Scroll vertical */
    border: 1px solid #ddd; /* Borde ligero para delimitar */
    border-radius: 8px; /* Bordes redondeados */
    padding: 8px; /* Espaciado interno */
  }
  
  /* Scroll personalizado para navegadores basados en WebKit (Chrome, Safari, Edge) */
  .custom-textarea::-webkit-scrollbar {
    width: 8px; /* Ancho del scroll */
  }
  
  .custom-textarea::-webkit-scrollbar-thumb {
    background: #39A9DF; /* Color del scroll */
    border-radius: 8px; /* Bordes redondeados */
  }
  
  .custom-textarea::-webkit-scrollbar-thumb:hover {
    background: #2C8AC0; /* Color más oscuro al pasar el mouse */
  }
  
  .custom-textarea::-webkit-scrollbar-track {
    background: #f3f3f3; /* Fondo del scroll */
    border-radius: 8px; /* Bordes redondeados del track */
  }
  